<template>
  <div>
    <CCard class="shadow-sm m-2">
        <CCardBody>
          <CRow>
            <CIcon size="2xl" name="cil-basket" class="text-success col-2 col-sm-1 col-lg-2-1" />
            <CCol col="8" sm="9" md="9" lg="9">
              <select class="custom-select text-dark" v-model="shopObjectId" disabled>
                <option selected>{{ $t('selectshop') }}</option>
                <option v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>
            </CCol>
            <div class="col-2 col-sm-2 col-md-2 text-right">
              <CButton block color="light" @click="$router.go(-1)" class="align-items-center">
                <span><i class="fa fa-arrow-left mr-2"></i></span> {{ $t('back') }}
              </CButton>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    <CCard class="shadow-sm m-2">
      <CCardBody class="text-dark">
        <CRow class="align-items-center">
          <CCol class="col-9 col-sm-10 col-md-10">
            <h4>{{ $t("purchaseOrderDetail") }} - #{{ orderDetailData.order_sn }}</h4>
          </CCol>
          <div class="col-3 col-sm-2 col-md-2 ">
              <CButton block color="success" class="align-items-center" @click="" variant="outline">
                <i class="fa fa-print" style="color: #29b289; margin-right: 8px"></i>{{ $t("printShippingLabel") }}
              </CButton>
          </div>
        </CRow>

        <!-- Shipping Address Section -->
        <CRow class="mt-4">
          <CCol col="12" style="margin-left: 20px">
            <CRow class="align-items-center mb-2">
              <i class="fi fi-rr-marker"></i>
              <span class="ml-4 mb-0"
                ><strong>{{ $t("shippingAddressTxt") }}</strong></span
              >
            </CRow>
            <CRow>
              <CCol col="3" class="ml-5">
                <p class="mb-1">
                  <strong>{{ $t("customerAccount") }} :</strong>
                </p>
                <p class="mb-1">
                  <strong>{{ $t("telShippig") }} :</strong>
                </p>
                <p class="mb-1">
                  <strong>{{ $t("addressShipping") }} :</strong>
                </p>
              </CCol>
              <CCol col="7">
                <p class="mb-1">{{ orderDetailData.buyer_username }}</p>
                <p class="mb-1">{{ orderDetailData.recipient_address.phone }}</p>
                <p class="mb-1">{{ orderDetailData.recipient_address.name }} {{ orderDetailData.recipient_address.full_address }}</p>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <hr />

        <CRow class="mt-4 align-items-center" style="margin-left: 20px">
          <CCol col="8">
            <CRow class="align-items-center mb-2">
              <i class="fi fi-rr-box"></i>
              <span class="ml-4 mb-0 text-bold">{{ $t('shippingDetailTxt') }} - {{ orderDetailData.shipping_carrier }}</span>
            </CRow>
          </CCol>
        </CRow>

        <CRow style="margin-left: 20px">
          <CCol>
            <p class="mb-1 ml-5"><strong></strong></p>
          </CCol>
        </CRow>
        <CRow style="margin-left: 20px">
          <CCol col="3" class="ml-5">
            <p class="mb-1">Tracking No. : </p>
            <p class="mb-1">{{ $t("status") }} :</p>
          </CCol>
          <CCol col="7">
            <p class="mb-1">{{ trackingNo }}</p>
            <p class="mb-1">{{ orderDetailData.order_status }}</p>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CCol style="margin-left: 13px; margin-bottom: 20px">
          <CRow class="mb-2 align-items-center">
            <CCol>
              <i class="fi fi-rr-shopping-basket"></i>
              <span class="ml-4 mb-0 text-bold">{{ $t("productDetail") }}</span>
            </CCol>
          </CRow></CCol
        >
        <DataTable :items="orderItems" :fields="fields" responsive>
          <template #image="{ item }">
            <div class="d-flex justify-content-center align-items-center" style="padding: 10px">
              <img :src="item.image" fluid alt="Product Image" style="width: 50px; height: 50px"/>
            </div>
          </template>
        </DataTable>
      </CCardBody>
    </CCard>
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CCol style="margin-left: 13px; margin-bottom: 20px">
          <CRow class="mb-2 align-items-center">
            <CCol>
              <i class="fi fi-rr-shopping-basket"></i>
              <span class="ml-4 mb-0 text-bold">{{ $t("paymentDetailTxt") }}</span>
            </CCol>
            <div class="col-3 col-sm-2 col-md-2 ">
              <CButton block color="success" class="align-items-center" v-show="showPullButton && status" @click="orderPull()" variant="outline">
                <i class="fa fa-print" style="color: #29b289; margin-right: 8px"></i>{{ $t("printReceipt") }}
              </CButton>
          </div>
          </CRow> 
          </CCol>
        <DataTable :items="priceBreakdown" :fields="priceFields" responsive>
        </DataTable>
      </CCardBody>
    </CCard>
    <CModal :show.sync="errorModel" centered color="danger" size="lg">
          <div>
            <div class="d-flex flex-column align-items-center">
              <h4 class="text-center mt-3">{{ textError }}</h4>
            </div>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="$router.go(-1)">{{ $t('submit') }}</CButton>
          </div>
          </template>
        </CModal>
        <CModal :show.sync="pullSuccess" centered color="success" size="lg">
          <div>
            <div class="d-flex flex-column align-items-center">
              <h4 class="text-center mt-3">{{ $t('pullSuccess') }}</h4>
            </div>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="success" class="w-50" @click="closeModalAndRefresh">{{ $t('submit') }}</CButton>
          </div>
          </template>
        </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from "@/containers/DataTable"
import ecommerce from "@/services/ecommerce"
import { CCardBody } from '@coreui/vue'
import util from "@/util/util"

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      status: false,
      pullSuccess: false,
      trackingNo: '',
      showPullButton: false,
      textError: '',
      errorModel: false,
      orderDetailData: {
      order_sn: '',
      buyer_username: '',
      recipient_address: {
        name: '',
        phone: '',
        full_address: ''
      },
      shipping_carrier: '',
      package_number: '',
      order_status: '',
      item_list: []
    },
    orderItems: [], 
    orderItems: [
        {
          image: "",
          name: "",
          price: 0,
          quantity: 0,
        },
      ],
    statusOrder: ''
    };
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'access']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD-MM-YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD-MM-YYYY')
    },
    priceBreakdown() {
      return [
        { description: this.$t('itemTotal'), amount: this.orderDetailData.itemTotal },
        { description: this.$t('shippingFee'), amount: this.orderDetailData.estimated_shipping_fee },
        { description: this.$t('totalAmount'), amount: this.orderDetailData.total_amount },
      ];
    },
    priceFields() {
      return [
        { key: "description", label: this.$t(''), _classes: "text-dark", _style: "width:80%; text-", },
        { key: "amount", label: this.$t('docTotal'), _classes: "text-dark text-right",  _style: "width:10%; text-dark", },
      ];
    },
    fields() {
      return [
        { key: "id", label: "#", _classes: "text-dark text-center", _style: "width:5%; vertical-align: middle; text-dark",},
        { key: "image", label: "", _classes: "text-dark  text-center", _style: "width:15%; vertical-align: middle; text-dark" },
        { key: "name", label: this.$t("productName"), _classes: "text-dark", _style: "width:50%; vertical-align: middle; text-dark" },
        { key: "price", label: this.$t("price"), _classes: "text-dark text-right", _style: "width:15%; vertical-align: middle; text-dark" },
        { key: "quantity", label: this.$t("quantity"), _classes: "text-dark text-right", _style: "width:15%; vertical-align: middle; text-dark" }
      ];
    },
  },
  created() {
    this.orderDetail()
    this.orderCheck()
    this.orderTrackingNo()
  },
  watch: {
    statusOrder(newVal) {
      if (newVal) {
        this.statusCheck();
      }
    }
  },
  methods:{
    closeModalAndRefresh() {
      this.pullSuccess = false
      window.location.reload()
    },
    statusCheck() {
      let data = this.statusOrder
      if(data == 'READY_TO_SHIP' || data == 'PROCESSED' || data == 'SHIPPED' || data == 'COMPLETED' || data == 'IN_CANCEL' || data == 'CANCELLED' || data == 'INVOICE_PENDING'){
        this.status = true
      } else {
        this.status = false
      }
    },
    orderDetail() {
      const params = {
        shopObjectId: this.shopObjectId,
        orders: this.$route.params.order_sn,
      };
      ecommerce({
        url: "/api/v1.0/shopee/order/detail",
        params: params,
        method: "GET",
      })
      .then((response) => {
        if (response.data.error.message !== 'success') {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else if (response.data.data.length === 0) {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else {
          const data = response.data.data[0]
          const itemTotal = data.total_amount - data.estimated_shipping_fee;
          this.orderDetailData = {
            order_sn: data.order_sn,
            buyer_username: data.buyer_username,
            recipient_address: {
              name: data.recipient_address.name,
              phone: data.recipient_address.phone,
              full_address: data.recipient_address.full_address
            },
            shipping_carrier: data.shipping_carrier,
            package_number: data.package_list[0]?.package_number,
            order_status: this.getStatus(data.order_status),
            item_list: data.item_list.map((item, index) => ({
              id: index + 1,
              image: item.image_info.image_url,
              name: item.item_name,
              price: util.convertCurrency(item.model_original_price),
              quantity: item.model_quantity_purchased
            })),
            itemTotal: util.convertCurrency(itemTotal),
            estimated_shipping_fee: util.convertCurrency(data.estimated_shipping_fee),
            total_amount: util.convertCurrency(data.total_amount),
          }
          this.statusOrder = data.order_status
          this.orderItems = this.orderDetailData.item_list;
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    orderCheck() {
      const params = {
        shopObjectId: this.shopObjectId,
        orders: this.$route.params.order_sn,
      };
      ecommerce({
        url: "/api/v1.0/shopee/order/check",
        params: params,
        method: "GET",
      })
      .then((response) => {
        if (response.data.error.message !== 'success') {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else if (response.data.data.length === 0) {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else {
          const data = response.data.data
          if (data.exists.length == 0) {
              this.showPullButton = true
              if (this.showPullButton) {
                this.statusCheck()
              }
          } else {
            this.showPullButton = false
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    orderPull() {
      const params = {
        shopObjectId: this.shopObjectId,
      };
      const data = {
        order: this.$route.params.order_sn,
      }
      ecommerce({
        url: "/api/v1.0/shopee/order/pull",
        params: params,
        data: data,
        method: "POST",
      })
      .then((response) => {
        if (response.data.error.message !== 'success') {
          this.errorModel = true
          this.textError = this.$t('notFoundProduct')
        } else if (response.data.data.length === 0) {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else {
          this.pullSuccess = true
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    orderTrackingNo() {
      const params = {
        shopObjectId: this.shopObjectId,
        order: this.$route.params.order_sn,
      };
      ecommerce({
        url: "/api/v1.0/shopee/order/trackingnumber",
        params: params,
        method: "GET",
      })
      .then((response) => {
        if (response.data.error.message !== 'success') {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else if (response.data.data.length === 0) {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else {
          const data = response.data.data
          const trackingNo = data.tracking_number ? data.tracking_number : '-';
          this.trackingNo = trackingNo
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    getStatus(status) {
      switch (status) {
        case "SHIPPED":
          return this.$t('shipped');
        case "PAY_WAITING":
          return this.$t('payWaiting');
        case "SHIPPING":
          return this.$t('shipping');
        case "CANCELLED":
          return this.$t('cancelled');
        case "PAYED":
          return this.$t('payed');
        case "READY_TO_SHIP":
          return this.$t('readyToShip');
        default:
          return "-";
      }
    },
  }
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
</style>
